async function readPhoto(photo: File) {
  const canvas = document.createElement("canvas");
  const img = document.createElement("img");

  // create img element from File object
  img.src = await new Promise((resolve) => {
    const reader = new FileReader();
    // @ts-ignore
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(photo);
  });
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // draw image in canvas element
  canvas.width = img.width;
  canvas.height = img.height;
  canvas?.getContext("2d")?.drawImage(img, 0, 0, canvas.width, canvas.height);

  return canvas;
}

function scaleCanvas(canvas: any, scale: any) {
  const scaledCanvas = document.createElement("canvas");
  scaledCanvas.width = canvas.width * scale;
  scaledCanvas.height = canvas.height * scale;

  scaledCanvas
    .getContext("2d")
    ?.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

  return scaledCanvas;
}

export async function resize(file: File, maxWidth: number): Promise<Blob> {
  let canvas = await readPhoto(file);

  while (canvas.width >= 2 * maxWidth) {
    canvas = scaleCanvas(canvas, .5);
  }

  if (canvas.width > maxWidth) {
    canvas = scaleCanvas(canvas, maxWidth / canvas.width);
  }

  return new Promise((resolve) => {
    // @ts-ignore
    canvas.toBlob(resolve, "image/jpeg");
  });
}

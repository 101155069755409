import { consultarCep } from "@/utils/endereco";
import { ref } from "@vue/reactivity";

export function useBuscaCep(onFind: (resp: any) => void) {
  const loading = ref(false);
  const encontrou = ref(false);

  async function consultar(cep?: string) {
    encontrou.value = false;
    if (!cep) {
      return;
    }
    loading.value = true;
    try {
      const resp = await consultarCep(cep);
      if (!resp.erro) {
        encontrou.value = true;
        onFind(resp);
      }
    } catch (e) {
      console.log("Falha ao buscar cep", cep);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    encontrou,
    consultar,
  };
}

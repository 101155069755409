export const tiposPessoa = [
  { value: "F", label: "Pessoa Física" },
  { value: "J", label: "Pessoa Jurídica" },
];

export const estadoCivilOptions = [
  { value: "solteiro", label: "Solteiro(a)" },
  { value: "casado", label: "Casado(a)" },
  { value: "união estável", label: "União Estável" },
  { value: "divorciado", label: "Divorciado(a)" },
  { value: "viúvo", label: "Viúvo(a)" },
  { value: "outro", label: "Outro" },
];

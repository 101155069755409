<template>
  <div class="p-text-center" v-if="loading">
    <p-progress-spinner style="width: 50px; height: 50px" />
  </div>
  <div class="card" v-if="user">
    <form @submit.prevent="salvar" style="width: 100%" class="p-grid">
      <div class="p-col-12">
        <div class="p-d-flex p-ai-center p-m-2 p-flex-column">
          <Avatar />
          <FileUpload
            ref="avatarUpload"
            class="p-mt-2"
            mode="basic"
            chooseLabel="Alterar"
            auto
            customUpload
            @uploader="uploadAvatar"
            accept="image/png,image/jpg,image/jpeg"
          ></FileUpload>
        </div>
      </div>

      <div class="p-col-12">
        <h5>Meus Dados</h5>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="p-fluid">
          <div class="p-field">
            <label for="name">Meu ID</label>
            <p-inputtext type="text" :value="user.code" disabled />
          </div>
          <div class="p-field">
            <label>{{ isPF ? "Nome Completo" : "Razão Social" }}</label>
            <p-inputtext type="text" v-model="user.name" required />
          </div>
          <div class="p-field">
            <label>{{ isPF ? "Apelido" : "Nome Fantasia" }}</label>
            <p-inputtext type="text" v-model="user.pessoa.apelido" />
          </div>
          <div class="p-field">
            <label for="name">E-mail</label>
            <p-inputtext type="email" v-model="user.email" required />
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Telefone Fixo</label>
          <InputMask v-model="user.pessoa.telefoneFixo" mask="(99) 9999-9999" />
        </div>

        <div class="p-field">
          <label>Celular</label>
          <InputMask
            v-model="user.pessoa.telefone"
            required
            mask="(99) 99999-9999"
          />
        </div>
        <div class="p-field">
          <label>Whatsapp</label>
          <p-inputtext
            v-model="user.pessoa.whatsapp"
            v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
          />
        </div>
        <div class="p-field">
          <label>Telegram</label>
          <p-inputtext
            v-model="user.pessoa.telegram"
            v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <p-divider />
        <div class="p-field">
          <label>Tipo</label>
          <p-inputtext
            disabled
            :value="
              user.pessoa.tipo === 'J' ? 'Pessoa Jurídica' : 'Pessoa Física'
            "
          />
        </div>
        <div class="p-field">
          <label>{{ labelDocumento }}</label>
          <p-inputtext disabled :value="user.pessoa.documento" />
        </div>
        <div class="p-field">
          <label>{{ labelRgInsc }}</label>
          <p-inputtext v-model="user.pessoa.rgInsc" required />
        </div>
        <div class="p-field" v-if="isPF">
          <label style="width: 100%">Data Nascimento</label>
          <p-inputtext
            type="date"
            v-model="user.pessoa.dataNascimento"
            required
          />
        </div>
        <div class="p-field" v-if="isPF">
          <label>Sexo</label>
          <p-dropdown
            v-model="user.pessoa.sexo"
            required
            :options="[
              { value: 'masculino', label: 'Masculino' },
              { value: 'feminino', label: 'Feminino' },
              { value: '-', label: 'Prefiro não informar' },
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div class="p-field" v-if="isPF">
          <label>Estado Civil</label>
          <p-dropdown
            v-model="user.pessoa.estadoCivil"
            required
            :options="estadoCivilOptions"
            optionLabel="label"
            optionValue="value"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <p-divider />
        <div class="p-field">
          <label style="display: block">
            CEP
            <i v-if="cep.loading.value" class="pi pi-spin pi-spinner"></i>
          </label>
          <InputMask
            v-model="user.pessoa.cep"
            required
            mask="99999-999"
            @blur="cep.consultar(user?.pessoa.cep)"
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Logradouro</label>
          <p-inputtext v-model="user.pessoa.logradouro" required />
        </div>
        <div class="p-field">
          <label style="display: block">Número</label>
          <p-inputtext
            v-model="user.pessoa.enderecoNumero"
            required
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Complemento</label>
          <p-inputtext v-model="user.pessoa.complemento" />
        </div>
        <div class="p-field">
          <label>Referência</label>
          <p-inputtext v-model="user.pessoa.referencia" />
        </div>
        <div class="p-field">
          <label>Bairro</label>
          <p-inputtext v-model="user.pessoa.bairro" required />
        </div>
        <div class="p-field">
          <label>Cidade</label>
          <p-inputtext
            v-model="user.pessoa.cidade"
            required
            :disabled="cep.encontrou.value"
          />
        </div>
        <div class="p-field">
          <label>UF</label>
          <p-dropdown
            v-model="user.pessoa.uf"
            required
            :options="ufs"
            optionLabel="nome"
            optionValue="sigla"
            :disabled="cep.encontrou.value"
          />
        </div>
      </div>

      <div
        class="p-d-flex p-flex-row-reverse p-col-12"
        style="justify-content: end"
      >
        <p-button
          :icon="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          label="Salvar"
          type="submit"
          :disabled="saving"
        />
        <p-button
          class="p-button-secondary p-mr-2"
          label="Cancelar"
          @click="voltar"
        />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { User, getService, getUrlAvatar } from "@/services/UserService";
import { useStore, store } from "@/store";
import { MutationTypes } from "@/store/mutations";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { resize } from "@/utils/image";
import { estadoCivilOptions } from "@/utils/pessoas";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const saving = ref(false);
    const store = useStore();

    const user = ref<User>();

    const avatar = computed(() => {
      return user.value?.avatar ? getUrlAvatar(user.value) : "";
    });

    const loading = ref(false);
    async function load() {
      loading.value = true;
      try {
        const result = await getService().meAccount();
        user.value = result;
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      load();
    });

    const cep = useBuscaCep((resp) => {
      if (user.value) {
        user.value.pessoa.logradouro = resp.logradouro;
        user.value.pessoa.complemento = resp.complemento;
        user.value.pessoa.bairro = resp.bairro;
        user.value.pessoa.cidade = resp.localidade;
        user.value.pessoa.uf = resp.uf;
      }
    });

    return {
      ufs,
      cep,
      loading,
      saving,
      user,
      avatar,

      estadoCivilOptions,
    };
  },
  computed: {
    isPF(): boolean {
      return this.user?.pessoa.tipo !== "J";
    },
    labelDocumento(): string {
      return this.isPF ? "CPF" : "CNPJ";
    },
    labelRgInsc(): string {
      return this.isPF ? "RG" : "Insc. Estadual";
    },
  },
  methods: {
    async uploadAvatar(evt: { files: File[] }) {
      try {
        const file = await resize(evt.files[0], 200);
        await getService().uploadAvatar(file);
        const me = await getService().me();
        store.commit(MutationTypes.SET_USER, me);
      } finally {
        // @ts-ignore
        this.$refs.avatarUpload.clear();
      }
    },
    async salvar() {
      if (!this.user) {
        return;
      }
      this.saving = true;
      try {
        await getService().updateMe(this.user);
        const me = await getService().me();
        store.commit(MutationTypes.SET_USER, me);
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Atualizado com sucesso",
          life: 3000,
        });
      } finally {
        this.saving = false;
      }
    },
    voltar() {
      this.$router.push({ name: "dashboard" });
    },
  },
});
</script>

import { User, getService, getUrlAvatar } from "@/services/UserService";
import { useStore, store } from "@/store";
import { MutationTypes } from "@/store/mutations";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { resize } from "@/utils/image";
import { estadoCivilOptions } from "@/utils/pessoas";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const saving = ref(false);
    const store = useStore();

    const user = ref<User>();

    const avatar = computed(() => {
      return user.value?.avatar ? getUrlAvatar(user.value) : "";
    });

    const loading = ref(false);
    async function load() {
      loading.value = true;
      try {
        const result = await getService().meAccount();
        user.value = result;
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      load();
    });

    const cep = useBuscaCep((resp) => {
      if (user.value) {
        user.value.pessoa.logradouro = resp.logradouro;
        user.value.pessoa.complemento = resp.complemento;
        user.value.pessoa.bairro = resp.bairro;
        user.value.pessoa.cidade = resp.localidade;
        user.value.pessoa.uf = resp.uf;
      }
    });

    return {
      ufs,
      cep,
      loading,
      saving,
      user,
      avatar,

      estadoCivilOptions,
    };
  },
  computed: {
    isPF(): boolean {
      return this.user?.pessoa.tipo !== "J";
    },
    labelDocumento(): string {
      return this.isPF ? "CPF" : "CNPJ";
    },
    labelRgInsc(): string {
      return this.isPF ? "RG" : "Insc. Estadual";
    },
  },
  methods: {
    async uploadAvatar(evt: { files: File[] }) {
      try {
        const file = await resize(evt.files[0], 200);
        await getService().uploadAvatar(file);
        const me = await getService().me();
        store.commit(MutationTypes.SET_USER, me);
      } finally {
        // @ts-ignore
        this.$refs.avatarUpload.clear();
      }
    },
    async salvar() {
      if (!this.user) {
        return;
      }
      this.saving = true;
      try {
        await getService().updateMe(this.user);
        const me = await getService().me();
        store.commit(MutationTypes.SET_USER, me);
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Atualizado com sucesso",
          life: 3000,
        });
      } finally {
        this.saving = false;
      }
    },
    voltar() {
      this.$router.push({ name: "dashboard" });
    },
  },
});

export const ufs = [
  { sigla: "AC", nome: "Acre", ibge: 12 },
  { sigla: "AL", nome: "Alagoas", ibge: 27 },
  { sigla: "AM", nome: "Amazonas", ibge: 13 },
  { sigla: "AP", nome: "Amapá", ibge: 16 },
  { sigla: "BA", nome: "Bahia", ibge: 29 },
  { sigla: "CE", nome: "Ceará", ibge: 23 },
  { sigla: "DF", nome: "Distrito Federal", ibge: 53 },
  { sigla: "ES", nome: "Espírito Santo", ibge: 32 },
  { sigla: "EX", nome: "Exterior", ibge: 99 },
  { sigla: "GO", nome: "Goiás", ibge: 52 },
  { sigla: "MA", nome: "Maranhão", ibge: 21 },
  { sigla: "MG", nome: "Minas Gerais", ibge: 31 },
  { sigla: "MS", nome: "Mato Grosso do Sul", ibge: 50 },
  { sigla: "MT", nome: "Mato Grosso", ibge: 51 },
  { sigla: "PA", nome: "Pará", ibge: 15 },
  { sigla: "PB", nome: "Paraíba", ibge: 25 },
  { sigla: "PE", nome: "Pernambuco", ibge: 26 },
  { sigla: "PI", nome: "Piauí", ibge: 22 },
  { sigla: "PR", nome: "Paraná", ibge: 41 },
  { sigla: "RJ", nome: "Rio de Janeiro", ibge: 33 },
  { sigla: "RN", nome: "Rio Grande do Norte", ibge: 24 },
  { sigla: "RO", nome: "Rondônia", ibge: 11 },
  { sigla: "RR", nome: "Roraima", ibge: 14 },
  { sigla: "RS", nome: "Rio Grande do Sul", ibge: 43 },
  { sigla: "SC", nome: "Santa Catarina", ibge: 42 },
  { sigla: "SE", nome: "Sergipe", ibge: 28 },
  { sigla: "SP", nome: "São Paulo", ibge: 35 },
  { sigla: "TO", nome: "Tocantins", ibge: 17 },
];

export async function consultarCep(cep: string) {
  return await fetch(
    `https://viacep.com.br/ws/${cep.replace(/[^0-9]/g, "")}/json/`,
  ).then((r) => r.json());
}
